.Boot {
	background-color: black;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.Boot .progress-bar {
	background-color: black;
	background-image: linear-gradient(92deg, white, #ffffff);
	width: 200px;
	height: 2px;
	border-radius: 20px;
	animation: progress 10s ease-in-out;
	display: block;
	background-repeat: no-repeat;
}

.Boot .logo {
	font-size: 30px;
}

.Boot .text {
	font-size: 12px;
}

@keyframes progress {
	0% {
		background-position-x: -200px;
	}
	10% {
		background-position-x: -160px;
	}
	15% {
		background-position-x: -160px;
	}
	20% {
		background-position-x: -100px;
	}
	40% {
		background-position-x: -100px;
	}
	45% {
		background-position-x: -180px;
	}
	60% {
		background-position-x: -180px;
	}
	70% {
		background-position-x: -100px;
	}
	80% {
		background-position-x: -100px;
	}
	85% {
		background-position-x: -130px;
	}
	90% {
		background-position-x: -100px;
	}
	95% {
		background-position-x: -130px;
	}
	100% {
		background-position-x: 0px;
	}
}
