.TopBar {
	position: absolute;
	top: 0px;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	background-color: rgba(255, 255, 255, 0.374);
	backdrop-filter: blur(4px);
	padding: 0px 15px;
	box-shadow: 0 0 10px -3px #0000006e;
	max-width: 100%;
	color: rgba(0, 0, 0, 0.8);
	font-size: 15px;
	max-height: 24px;
	z-index: 10;
	user-select: none;
}

.theme-dark .TopBar {
	background-color: rgba(0, 0, 0, 0.374);
	color: white;
}

.TopBar .item:not(:last-child) {
	margin-right: 15px;
}

/*
** -- TOPBAR MENU
*/

.TopBarMenu {
	padding: 0px 7px;
	cursor: pointer;
}

.TopBarMenu.active {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

.TopBarMenu.active .MenuPopper {
	padding: 0.2rem 0px;
	background-color: var(--ctx-menu-color);
	border-bottom-left-radius: var(--radius-1);
	border-bottom-right-radius: var(--radius-1);
	box-shadow: 0px 0px 10px 4px var(--ctx-menu-shadow);
}

.MenuPopper hr {
	margin: 0.1rem 0px;
}

.TopBarMenu .MenuPopper .MenuItem {
	padding: 0rem 0.5rem;
	color: var(--font-color);
}

.TopBarMenu .MenuPopper .MenuItem:hover {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

/*
** -- USER MENU
*/

.UsersMenu {
	position: relative;
	cursor: pointer;
}

.UsersMenu .users-cont {
	background-color: rgba(230, 230, 230, 0.9);
	position: absolute;
	padding: 10px;
	right: 0px;
	top: 100%;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	min-width: 100px;
	width: fit-content;
}

.theme-dark .UsersMenu .users-cont {
	background-color: rgb(34 34 34 / 95%);
}

.UsersMenu .users-cont .user {
	padding: 5px;
	border-radius: 6px;
}

.UsersMenu .users-cont .user:not(:last-child) {
	margin-bottom: 0.1rem;
}

.UsersMenu .users-cont .user:hover {
	background-color: rgba(0, 0, 0, 0.113);
}

.theme-dark .UsersMenu .users-cont .user:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.UsersMenu .users-cont .user.active {
	pointer-events: none;
	opacity: 0.6;
}

.UsersMenu .users-cont .user-image {
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 100px;
	width: 30px;
	height: 30px;
	aspect-ratio: 1;
}

.UsersMenu .users-cont .user-name {
	font-size: 12px;
	line-height: 15px;
	width: max-content;
}

.UsersMenu .users-cont hr {
	margin: 6px 0px;
	margin-top: 8px;
}
