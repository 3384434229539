* {
	--color-primary: #3b83cc;
	--background-color: #f2f2f4;
	--background-color-darken: #eaeaea;
	--background-color-rgb: 242, 242, 244;
	--background-color-secondary: #ffffff;
	--background-color-secondary-rgb: 255, 255, 255;
	--font-color: black;
	--color-primary-font-color: #ffffff;

	--window-bg-color: #dbdbdbd9;
	--window-topbar-bg-color: #ffffff52;
	--window-topbar-btn: #d0d0d0;

	--ctx-menu-color: #e6e6e6e6;
	--ctx-menu-shadow: #00000044;

	--radius-0: 0px;
	--radius-1: 3px;
	--radius-2: 6px;
	--radius-3: 12px;
	--radius-4: 24px;
	--radius-5: 36px;
}

.theme-dark * {
	--background-color: #2c2c2c;
	--background-color-rgb: 44, 44, 44;
	--background-color-darken: #323232;
	--background-color-secondary: #1f1f1f;
	--background-color-secondary-rgb: 31, 31, 31;
	--font-color: white;

	--window-bg-color: #2a2a2aee;
	--window-topbar-btn: grey;
	--window-topbar-bg-color: #00000052;

	--ctx-menu-color: #151515ee;
	--ctx-menu-shadow: #00000044;
}

.App {
	height: 100vh;
	color: var(--font-color);
}

.wrapper {
	height: 100vh;
	width: 100vw;
	background-color: #467e8f;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.fullscreen-cont {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100vh;
	z-index: 10;
}

a,
a:hover {
	color: inherit;
	text-decoration: none;
}

.disabled {
	cursor: no-drop;
	pointer-events: none;
}

.no-select {
	user-select: none;
}

/*
**	-- ScrollBar
*/

::-webkit-scrollbar {
	width: 4px;
	height: 6px;
	margin: 5px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background: #e0e0e0a1;
}

::-webkit-scrollbar-corner {
	background: transparent;
}
