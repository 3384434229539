.Glitches {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100vh;
	z-index: 100;
	color: white;
}

.glitches-cont {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
}

.blue-screen {
	background-color: #0000aa;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100vh;
	z-index: 100;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 3vh;
	font-family: monospace;
	padding: 20px;
}
