.LogScreen {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	transition-duration: 0.6s;
	transition-timing-function: ease-out;
	pointer-events: none;
}

.LogScreen.initied {
	pointer-events: all;
}

.LogScreen .users-list {
	display: flex;
}
