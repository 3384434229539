.Dock {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	z-index: 3;
	margin-top: 3px;
}

.Dock.auto-hide {
	position: absolute;
	bottom: 0;
	margin-top: 0;
}

.dock-cont {
	pointer-events: all;
	background-color: rgba(255, 255, 255, 0.374);
	backdrop-filter: blur(4px);
	padding: 6px;
	padding-bottom: 5px;
	border-radius: 6px 6px 0px 0px;
	display: flex;
	flex-direction: row;
}

.theme-dark .dock-cont {
	background-color: rgba(0, 0, 0, 0.374);
}

.dock-cont > div {
	display: flex;
}

.dock-cont > *:empty {
	display: none;
}

.dock-cont > div:not(:last-child)::after {
	content: "";
	border-right: 1px solid white;
	height: 90%;
	margin: 0px 5px;
}

.dock-cont .opened-apps:empty {
	display: none;
}
