.file {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 15px;
	cursor: pointer;
}

.file .icon {
	width: 60px;
	height: 60px;
	/* border: 1px dashed red; */
	border-radius: 10px;
	margin-bottom: 4px;
	aspect-ratio: 1;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}

.file .name {
	color: rgb(32, 32, 32);
	text-align: center;
	line-height: 14px;
	font-size: 13px;
}

.file-icon {
	background-image: url(assets/file.png);
}

.folder-icon {
	background-image: url(assets/folder.png);
}
