.Wallpaper {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 0;
	transition-duration: 1s;
}
