.Notes {
	display: flex;
	height: 100%;
	width: 100%;
	color: #2a2a2a;
	background-color: #ffdaa9ba;
	font-size: 12px;
}

.Notes .side-panel {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 150px;
	max-width: 250px;
	padding: 10px;
	padding-bottom: 6px;
	padding-top: 25px;
	background-color: #ffc06fba;
	box-shadow: 0px 0px 12px 5px #00000021;
	z-index: 1;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.Notes .note-edit-cont {
	width: 100%;
	padding: 10px;
	min-width: 250px;
}

.Notes .add-note {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	border-radius: 6px;
	font-size: 12px;
	padding: 5px;
}

.Notes .add-note:hover {
	background-color: rgba(255, 255, 255, 0.15);
}

.Notes .add-note .add-btn {
	background-color: rgba(255, 255, 255, 0.34);
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1;
	line-height: 0;
	border-radius: 5px;
}

/*
** -- Markdown overloads
*/

.toastui-editor-toolbar {
	height: 46px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background-color: black;
	border-radius: 6px;
}

.toastui-editor-defaultUI button {
	border-radius: 4px;
	background-color: #ffffff;
	filter: invert(1);
}

.toastui-editor-dropdown-toolbar {
	background-color: #181818;
	border: 1px solid black;
	border-radius: 6px;
}

.toastui-editor-contents h1,
.toastui-editor-contents h2,
.toastui-editor-contents h3,
.toastui-editor-contents h4,
.toastui-editor-contents h5,
.toastui-editor-contents h6 {
	border-bottom: none;
}

.toastui-editor-contents blockquote {
	margin: 14px 0;
	border-left: 6px solid #00000069;
	border-radius: 6px;
	background-color: #ffffff33;
	padding: 5px 15px;
	color: #999;
	font-style: italic;
}

/* lists */
.toastui-editor-contents ul > li::before {
	content: "";
	margin-top: 6px;
	margin-left: -17px;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: #464646;
}

.toastui-editor-contents ol > li::before {
	content: "." counter(li);
	margin-left: -28px;
	width: 24px;
	text-align: right;
	direction: rtl;
	color: #464646;
}
/* checkobox */
.toastui-editor-contents .task-list-item::before {
	filter: brightness(0.2);
}

.toastui-editor-contents .task-list-item.checked::before {
	filter: grayscale(1) brightness(0.4);
}

/* hr */
.toastui-editor-contents hr {
	border-top: none;
	margin: 10px 0;
}

/* table */

.toastui-editor-contents table th:first-child {
	border-top-left-radius: 6px;
}

.toastui-editor-contents table th:last-child {
	border-top-right-radius: 6px;
}

.toastui-editor-contents table tr:last-child td:first-child {
	border-bottom-left-radius: 6px;
}

.toastui-editor-contents table tr:last-child td:last-child {
	border-bottom-right-radius: 6px;
}
