.react-grid-item {
	backdrop-filter: blur(10px);
}

.react-grid-item.react-grid-placeholder {
	background-color: transparent;
	display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-s:after {
	right: 50%;
	top: -2px;
	bottom: unset;
	transform: rotate(45deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
	bottom: 0;
	transform: rotate(0deg);
	width: 100%;
	left: 0;
	height: 5px;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
	right: 0;
	transform: rotate(0deg);
	height: 100%;
	top: 0;
	width: 5px;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-e:after {
	top: 50%;
	right: 2px;
	bottom: unset;
	transform: rotate(-45deg);
}

.react-draggable-dragging .Window,
.resizing .Window {
	pointer-events: none;
}

.Window {
	min-width: 200px;
	min-height: 50px;
	border-radius: 10px;
	height: 100%;
	width: 100%;
	padding: 0px 5px;
	padding-top: 20px;
	overflow: hidden;
	position: relative;
	background-color: rgba(0, 0, 0, 0.326);
	filter: brightness(0.8);
	z-index: 0;
	box-shadow: 0 0 10px 0px #00000020;
}

.Window.loaded {
	background-color: var(--window-bg-color);
}

.Window.focused {
	filter: brightness(1);
	z-index: 1;
	box-shadow: 0 0 10px 0px #00000040;
}

.react-resizable-handle {
	filter: invert(1);
	z-index: 1;
}

/*
** -- TopBar
*/

.Window .topbar {
	background-color: var(--window-topbar-bg-color);
	color: var(--font-color);
	backdrop-filter: blur(10px);
	top: 0;
	left: 0;
	padding: 1px 2px;
	position: absolute;
	font-size: 12px;
	border-bottom-right-radius: 6px;
	z-index: 10;
	width: max-content;
	max-width: 90%;
}

.Window .topbar .window-btn {
	margin: 3px;
	color: transparent;
	width: 12px;
	height: 12px;
	border-radius: 100px;
	line-height: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 8px;
}

.Window .topbar:hover .window-btn {
	color: black;
	cursor: pointer;
}

.window-btn.close {
	background-color: red;
}

.window-btn.reduce {
	background-color: orange;
}

.window-btn.maximize {
	background-color: #04bb04;
}

.Window .topbar .title {
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
}

.Window .topbar .history-btns {
	border-radius: 3px;
	margin: 3px;
	z-index: 10;
	flex-wrap: unset;
}

.Window .topbar .history-btns .arrow {
	padding: 2px 5px;
	font-size: 8px;
	cursor: pointer;
	background-color: var(--window-topbar-btn);
}

.Window .topbar .history-btns .arrow.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.Window .topbar .history-btns *:first-child {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.Window .topbar .history-btns *:last-child {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.Window .topbar .history-btns .arrow:hover {
	filter: brightness(0.8);
}

/*
** -- Cont
*/

.Window .window-cont {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	overflow: auto;
}

/*
** -- loading
*/

.Window .window-load {
	width: 100%;
	height: 100%;
	background-color: #00000005;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: wait;
}

.Window .window-load .loading-spinner {
	background-image: url("loading.png");
	padding: 9px;
	background-position: center;
	background-size: contain;
	transform: rotate(0deg);
	animation: spinner 1.5s ease-in-out infinite;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	30% {
		transform: rotate(-70deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/*
** -- Error
*/

.error-window-btn {
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 6px;
	padding: 2px 5px;
	width: fit-content;
	margin: auto;
	cursor: pointer;
}
