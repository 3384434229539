.WPSettings {
	display: flex;
}

.WPSettings .preview {
	border: 3px solid rgb(239 239 239);
	width: 50%;
	aspect-ratio: 16/9;
	border-radius: 12px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;
}

.theme-dark .WPSettings .preview {
	border: 3px solid #00000032;
	color: white;
}

.WPSettings .wp-list {
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	padding: 5px;
	border-radius: 12px;
	background-color: rgba(255, 255, 255, 0.5);
	overflow-y: scroll;
}

.theme-dark .WPSettings .wp-list {
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
}

.WPSettings .WpPreview {
	display: inline-flex;
	margin: 5px;
	cursor: pointer;
	position: relative;
}

.WPSettings .WpPreview:hover .name {
	opacity: 1;
	transition-duration: 0.5s;
}

.WPSettings .WpPreview .image {
	width: 100px;
	aspect-ratio: 16/9;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 6px;
	border: 2px solid rgb(255, 255, 255);
}

.theme-dark .WPSettings .WpPreview .image {
	border: 2px solid rgba(0, 0, 0, 0.5);
}

.WPSettings .WpPreview .name {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(1px);
	font-size: 12px;
	color: white;
	border: 2px solid rgb(255, 255, 255);
	transition-duration: 0.3s;
}

.theme-dark .WPSettings .WpPreview .name {
	border: 2px solid rgba(0, 0, 0, 0.2);
}

.WPSettings .top-cont > *:not(:first-child) {
	border-left: 1px solid rgba(255, 255, 255, 0.404);
	margin-left: 10px;
	padding-left: 10px;
}
