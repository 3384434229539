.DockFolder {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
	cursor: pointer;
}

.folder-cont {
	position: absolute;
	bottom: 60px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #000000f4;
	padding: 10px;
	border-radius: 10px;
	backdrop-filter: blur(10px);
	display: none;
	overflow: hidden;
}

.DockFolder.open .folder-cont {
	display: flex;
	overflow: visible;
}

.DockFolder .icon {
	width: 40px;
	height: 40px;
	border-radius: 10px;
	margin-bottom: 4px;
	aspect-ratio: 1;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.DockFolder:not(.in-folder) > .folder-name {
	position: absolute;
	top: -40px;
	background-color: #000000d6;
	padding: 0px 10px;
	border-radius: 4px;
	display: none;
	font-size: 16px;
}

.DockFolder.open .folder-name,
.DockFolder:hover .folder-name {
	display: block;
}

.DockFolder.in-folder {
	display: flex;
}

.DockFolder .folder-name::after {
	content: "";
	border: 10px solid transparent;
	border-top: 10px solid #000000d6;
	width: 3px;
	height: 3px;
	position: absolute;
	bottom: -16px;
	left: calc(50% - 10px);
	right: calc(50% - 10px);
}

.folder-open .DockFolder:not(.open) .folder-name {
	display: none;
}

.folder-cont {
	cursor: default;
	z-index: 1;
}

.DockFolder:not(.open) .folder-cont {
	pointer-events: none;
}

.folder-cont::after {
	content: "";
	border: 10px solid transparent;
	border-top: 10px solid #000000d6;
	width: 3px;
	height: 3px;
	position: absolute;
	bottom: -16px;
	left: calc(50% - 10px);
	right: calc(50% - 10px);
}

.folder-inner-cont {
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	max-width: 40vw;
	width: max-content;
	flex-wrap: wrap;
	justify-content: center;
}

.back-button {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(71, 71, 71);
	border-radius: 6px;
	width: 18px;
	height: 18px;
	overflow: hidden;
	font-size: 12px;
	cursor: pointer;
}
