.notes-list {
	height: 100%;
	overflow: auto;
	border-bottom: 1px solid #00000017;
	margin-bottom: 5px;
	padding-bottom: 15px;
}

.notes-list .note {
	padding: 2px 5px;
	border-radius: 6px;
	margin-bottom: 2px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.notes-list .note .note-title {
	word-break: keep-all;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.notes-list .note:not(:last-child) {
	/* border-bottom: 1px solid white; */
}

.notes-list .note.active {
	background-color: rgba(255, 255, 255, 0.2);
}

.notes-list .note:not(.active):hover {
	background-color: rgba(255, 255, 255, 0.15);
}
