.placeholder {
	pointer-events: none;
	cursor: default;
	background-color: transparent;
}

.toastui-editor-defaultUI {
	border: none;
	background-color: transparent;
}

.toastui-editor-main-container * *:not(.placeholder) {
	color: #fff;
	color: var(--font-color) !important;
	margin-top: 15px;
}

.toastui-editor-defaultUI .ProseMirror {
	padding: 0;
	padding-bottom: 15px;
}

.toastui-editor-defaultUI-toolbar {
	border-radius: 6px 6px 0 0;
	background-color: #f7f9fc;
	background-color: transparent;
	border-bottom: 1px solid #ebedf2;
	border-bottom: 1px solid var(--background-color-secondary);
	border-bottom: 1px solid #0000001c;
	display: flex;
	flex-wrap: wrap;
	padding: 0;
}

.toastui-editor-defaultUI-toolbar button {
	border: 1px solid #f7f9fc;
}

.toastui-editor-toolbar-divider {
	background-color: #e1e3e9;
}

.toastui-editor-ww-container {
	border-radius: 0px 0px 6px 6px;
	background-color: #fff;
	background-color: transparent;
	color: var(--font-color);
}

.toastui-editor-mode-switch {
	background-color: #fff;
	background-color: var(--background-color-secondary);
	border-top: 1px solid #dadde6;
	border-top: none;
}

.toastui-editor-mode-switch .tab-item {
	background: #f7f9fc;
	background: var(--background-color-darken);
	border: none;
}

.toastui-editor-mode-switch .tab-item.active {
	border-top: none;
	background-color: #fff;
	background-color: var(--background-color-lighten);
	color: #fff;
	color: var(--font-color);
}

.toastui-editor-main .toastui-editor-md-splitter {
	background-color: #ebedf2;
	background-color: var(--background-color-secondary);
}

.toastui-editor-popup {
	background: #fff;
	background-color: var(--background-color-secondary);
	border: 1px solid #dadde6;
	border: 1px solid var(--background-color);
	border-radius: 6px;
}

.toastui-editor-popup-body input[type="text"] {
	font-family: "SF-Text-Light" !important;
	font-size: 15px !important;
	color: var(--font-color);
}

.toastui-editor-defaultUI button {
	border-radius: 4px;
	background-color: #ffffff;
}

.toastui-editor-defaultUI .toastui-editor-ok-button {
	background-color: #00a9ff;
	background-color: var(--color-primary);
	outline-color: #009bf2;
	outline-color: var(--color-primary);
	color: #fff;
}

.toastui-editor-popup-body label {
	color: #555;
	color: var(--font-color);
}

.toastui-editor-dropdown-toolbar {
	border: 1px solid #dadde6;
	background-color: #f7f9fc;
	background-color: var(--background-color-darken);
	background-color: #ffdaa9ba;
	border: 1px solid var(--background-color-secondary);
}
