.icon-cont {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	cursor: pointer;
	width: 50px;
	z-index: 2;
}

.icon-cont .icon {
	width: 40px;
	height: 40px;
	border-radius: 10px;
	margin-bottom: 4px;
	aspect-ratio: 1;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.icon-cont .active-dot {
	width: 4px;
	height: 4px;
	border-radius: 100px;
	background-color: white;
	opacity: 0;
	transition-duration: 0.3s;
}

.icon-cont.running .active-dot {
	opacity: 1;
	transition-duration: 0.6s;
}

.icon-cont .icon .alias {
	color: black;
	position: absolute;
	bottom: 0px;
	right: 0px;
	border-top-left-radius: 5px;
	overflow: hidden;
}

/*
** -- Docks Icon
*/
.docked.icon-cont .file-name {
	position: absolute;
	top: -40px;
	background-color: #000000d6;
	padding: 0px 10px;
	border-radius: 4px;
	display: none;
	z-index: 2;
}

.docked.icon-cont:hover .file-name {
	display: block;
}

.docked .file-name::after {
	content: "";
	border: 10px solid transparent;
	border-top: 10px solid #000000d6;
	width: 3px;
	height: 3px;
	position: absolute;
	bottom: -16px;
	left: calc(50% - 10px);
	right: calc(50% - 10px);
}

.folder-open .docked.icon-cont .file-name {
	display: none;
}

/*
** -- infolder 
*/
.icon-cont.in-folder {
	padding: 5px;
	width: 70px;
	border-radius: 6px;
}

.icon-cont.in-folder:hover {
	background-color: rgba(255, 255, 255, 0.134);
}

.icon-cont.in-folder {
	font-size: 12px;
}

.icon-cont.in-folder .active-dot {
	opacity: 0;
}
