.Home {
	display: flex;
	flex-flow: column;
	z-index: 1;
	position: relative;
	color: white;
	width: 100vw;
	height: 100vh;
	padding-top: 24px;
	overflow: hidden;
}

.home-cont {
	height: 100%;
}

.magnet {
	background-color: var(--color-primary);
	position: absolute;
	z-index: 10;
	opacity: 0.3;
	border-radius: 10px;
	transition-duration: 0.2s;
}
