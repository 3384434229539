.User {
	color: white;
	padding: 30px;
	margin: 10px;
	/* background-color: #FFFFFF55; */
	/* backdrop-filter: blur(8px); */
	border-radius: 15px;
	transition-duration: 0.3s;
	min-width: 130px;
}

.User:not(.active):hover {
	box-shadow: 0 0 3px 1px #00000039;
	background-color: #00000039;
	transform: scale(0.95);
	cursor: pointer;
}

.User.active {
	transform: scale(0.95);
	transition-duration: 0s;
}

.User .user-image {
	background-image: url(assets/avatar.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 100px;
	width: 50px;
	height: 50px;
	aspect-ratio: 1;
}

.User .input-cont {
	background-color: rgba(255, 255, 255, 0.442);
	border: 1px solid #ffffff0a;
	border-radius: 6px;
	color: white;
	text-align: center;
}

.User .password {
	border: none;
	border-radius: 6px;
	color: white;
	text-align: center;
	background-color: transparent;
	padding: 0px 22px;
	outline: none;
}

.User .pass-cont {
	/* margin-left: calc(-20px - 0.5rem); */
	position: relative;
}

.User .pass-cont .back-arrow,
.User .pass-cont .log-arrow {
	border-radius: 50%;
	border: 1px solid rgba(255, 255, 255, 0.7);
	aspect-ratio: 1;
	line-height: 0;
	font-size: 8px;
	padding: 3px;
	mix-blend-mode: saturation;
	cursor: pointer;
}

.User .pass-cont .change-user {
	cursor: pointer;
	color: rgba(255, 255, 255, 0.7);
	transition-duration: 0.2s;
	font-size: 12px;
}

.User .pass-cont .change-user:hover {
	cursor: pointer;
	color: rgba(255, 255, 255, 1);
}

.User .pass-cont .change-user:hover .back-arrow {
	border-color: white;
}

.User .pass-cont .log-arrow {
	font-size: 8px;
	padding: 3px;
	border: 1px solid white;
	position: absolute;
	right: 0;
}

.User .pass-cont.wrong {
	animation: wrong_pass 0.5s ease-in-out;
}

@keyframes wrong_pass {
	0% {
		left: -0px;
	}
	20% {
		left: -10px;
	}
	40% {
		left: 10px;
	}
	60% {
		left: -5px;
	}
	80% {
		left: 5px;
	}
	100% {
		left: 0px;
	}
}
