.Gamebox {
	padding: 10px;
	padding-top: 25px;
	width: 100%;
	height: 100%;
}

.case {
	background: #d9d9d9;
	height: 100%;
	max-width: 100%;
	border-radius: 30px;
	border-bottom-right-radius: 60px;
	padding: 30px;
	box-shadow: 0 0 15px 0px #00000063;
	aspect-ratio: 9/14;
}

.screen-cont {
	background-color: grey;
	padding: 20px;
	border-radius: 10px;
	border-bottom-right-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(
		to bottom,
		#78768f 0%,
		#727089 52%,
		#78768f 100%
	);
}

.screen {
	width: 80%;
	background: linear-gradient(135deg, #ffffff 0%, #d7d7d7 100%);
	aspect-ratio: 1;
	border: 2px solid transparent;
	position: relative;
}

.screen-color {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	background-color: #a1cb00;
	opacity: 0.5;
}

.screen canvas {
	width: 100%;
	height: 100%;
	z-index: 0;
	filter: grayscale(1);
}

.screen canvas.debug-canvas,
.screen canvas.Minimap {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

canvas {
	z-index: 1;
	position: relative;
}
.Minimap {
	background-color: white;
	height: auto;
	position: absolute;
	z-index: 0;
}

.arrows {
	background: linear-gradient(to bottom, #b9b5b4 0%, #e6e4e4 100%);
	width: 40%;
	aspect-ratio: 1;
	border-radius: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.arrows .arrow {
	background-color: rgb(60, 60, 60);
	border-radius: 6px;
	cursor: pointer;
}

.arrow-center {
	background-color: black;
	border-radius: 100px;
	width: 10px;
	height: 10px;
	aspect-ratio: 1;
	margin: 5px;
}

.arrow:hover {
	background-color: rgb(40, 40, 40);
}

.arrow.pressed {
	background-color: rgb(20, 20, 20);
}

.arrow.up,
.arrow.down {
	width: 20%;
	min-width: 25px;
	height: 30%;
}

.arrow.left,
.arrow.right {
	min-height: 25px;
	height: 20%;
	width: 35%;
}

.buttons-cont {
	margin-top: 20%;
}

.buttons {
	background: linear-gradient(to bottom, #b9b5b4 0%, #e6e4e4 100%);
	width: 20%;
	min-width: 100px;
	height: 50%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 1000px;
	padding: 10px;
	transform: rotate(-33deg);
}

.buttons .a,
.buttons .b {
	background-color: #cb2667;
	width: 40%;
	min-width: 30px;
	aspect-ratio: 1;
	border-radius: 1000px;
	flex-basis: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transform: rotate(33deg);
	color: #4a001d;
}

.buttons .a:hover,
.buttons .b:hover,
.buttons .a.pressed,
.buttons .b.pressed {
	background-color: #b91959;
}
