.Explorer {
	display: flex;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.374);
	color: black;
}

.theme-dark .Explorer {
	background-color: rgba(62, 62, 62, 0.5);
	backdrop-filter: blur(10px);
}

.theme-dark .Explorer .file-name {
	color: white;
}

.Explorer .explorer-cont {
	padding: 15px;
	display: flex;
	height: fit-content;
	max-height: 100%;
	width: 100%;
	flex-wrap: wrap;
	overflow: auto;
}

/*
** -- Menu
*/

.Explorer .ExplorerMenu {
	width: 200px;
	background-color: rgba(255, 255, 255, 0.374);
	height: 100%;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	padding: 20px 7px;
}

.theme-dark .Explorer .ExplorerMenu {
	background-color: rgba(0, 0, 0, 0.374);
	color: white;
}

.Explorer .ExplorerMenu .item {
	cursor: pointer;
}
