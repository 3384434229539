.Settings {
	width: 100%;
	height: 100%;
	padding: 40px 20px 20px 20px;
	color: var(--font-color);
	display: flex;
}

.active-cont {
	/* position: absolute; */
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 2;
}

/*
** -- App
*/

.SettingsApp {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	padding: 5px;
}

.SettingsApp:hover {
	background-color: rgba(0, 0, 0, 0.07);
	border-radius: 6px;
	cursor: pointer;
}

.SettingsApp .icon {
	overflow: hidden;
	width: 50px;
	height: 50px;
	border-radius: 12px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
