/*
** -- Switch
*/

.switch {
	position: relative;
	box-sizing: border-box;
	height: 20px;
	cursor: pointer;
	width: 45px;
	margin-left: 0px;
	-moz-appearance: initial;
	background-color: transparent;
	z-index: 0;
}

.switch::before {
	background-color: #b6b6b6;
	width: 100%;
	display: block;
	content: " ";
	height: 100%;
	border-radius: 20px;
	z-index: 1;
	position: relative;
	transition-duration: 0.5s;
}

.switch::after {
	background-color: #00000060;
	content: " ";
	display: block;
	width: 18px;
	height: 18px;
	z-index: 2;
	position: absolute;
	border-radius: 50%;
	top: 1px;
	left: 2px;
	transition-duration: 0.5s;
}

.switch:checked::before {
	background-color: #2e8aba;
	background-color: var(--color-primary);
}

.switch:checked::after {
	left: calc(100% - 20px);
}
